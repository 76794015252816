// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #791B37;
    border-bottom: 2px solid #fff;
}

.footer-section  {
    margin: auto;
    max-width: 1270px;
    display: grid;
    grid-template-columns: repeat(4 , 1fr);
    padding: 80px 10px 40px 10px;
}

.footer-logo {
    color: rgb(236, 64, 122);
    font-size: 30px;
}

.footer-services h2 {
    font-size: 25px;
}
.footer-services h2,.f-links {
    color: #fff;
}

.footer-links, .footer-caption,.footer-sec{
    display: flex;
    flex-direction: column;   
    gap: 4px;
}

.footer-caption,.footer-sec, .footer-logo {
    margin-top: 40px;
}

.footer-links {
    margin-top: 15px ;

}`, "",{"version":3,"sources":["webpack://./src/Componants/Footercomponant/footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,sCAAsC;IACtC,4BAA4B;AAChC;;AAEA;IACI,wBAAwB;IACxB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;;AAErB","sourcesContent":[".footer {\n    background-color: #791B37;\n    border-bottom: 2px solid #fff;\n}\n\n.footer-section  {\n    margin: auto;\n    max-width: 1270px;\n    display: grid;\n    grid-template-columns: repeat(4 , 1fr);\n    padding: 80px 10px 40px 10px;\n}\n\n.footer-logo {\n    color: rgb(236, 64, 122);\n    font-size: 30px;\n}\n\n.footer-services h2 {\n    font-size: 25px;\n}\n.footer-services h2,.f-links {\n    color: #fff;\n}\n\n.footer-links, .footer-caption,.footer-sec{\n    display: flex;\n    flex-direction: column;   \n    gap: 4px;\n}\n\n.footer-caption,.footer-sec, .footer-logo {\n    margin-top: 40px;\n}\n\n.footer-links {\n    margin-top: 15px ;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
