// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copywrite {
    background-color: #791B37;
    color: #fff;
    padding: 40px 90px;
    margin: auto;
    font-size: x-large;
}`, "",{"version":3,"sources":["webpack://./src/Componants/Copywritecomponant/copywrite.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".copywrite {\n    background-color: #791B37;\n    color: #fff;\n    padding: 40px 90px;\n    margin: auto;\n    font-size: x-large;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
