// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.find-doctor-componant {
    width: 100%;
    margin: 100px 0px;
}

.find-doctor-container {
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns:repeat(3 , 1fr);

}`, "",{"version":3,"sources":["webpack://./src/Componants/FindDoctorComponant/finddoctor.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,qCAAqC;;AAEzC","sourcesContent":[".find-doctor-componant {\n    width: 100%;\n    margin: 100px 0px;\n}\n\n.find-doctor-container {\n    width: 70%;\n    margin: auto;\n    display: grid;\n    grid-template-columns:repeat(3 , 1fr);\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
