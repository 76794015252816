// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;   
}

.home1_right {
    margin-left: 5px;
}

.home1_left {
    font-size: 32px;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #333333;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;
}

.homeend2 {
    display: block;
    font-family: 'Raleway';
    font-weight: normal;
    font-style: normal;
    font-size: 16px;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #33333350;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;
    word-spacing: 8px;
    text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/Componants/HomeEnd2Componant/Homeend2.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,cAAc;IACd,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,gBAAgB;IAChB,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".home1 {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;   \n}\n\n.home1_right {\n    margin-left: 5px;\n}\n\n.home1_left {\n    font-size: 32px;\n    font-style: normal;\n    font-weight: bold;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #333333;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n}\n\n.homeend2 {\n    display: block;\n    font-family: 'Raleway';\n    font-weight: normal;\n    font-style: normal;\n    font-size: 16px;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #33333350;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n    word-spacing: 8px;\n    text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
