// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payments {
    width: 90%;
    margin: auto;
}

.payment_caption {
    line-height: 80px;
    font-size: 32px;
    color: #EE4C7C;
}

.scanner_codes {

}`, "",{"version":3,"sources":["webpack://./src/Componants/PaymentSection/payment.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;AAClB;;AAEA;;AAEA","sourcesContent":[".payments {\n    width: 90%;\n    margin: auto;\n}\n\n.payment_caption {\n    line-height: 80px;\n    font-size: 32px;\n    color: #EE4C7C;\n}\n\n.scanner_codes {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
