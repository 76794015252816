// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-doctor-section {
    width: 100vw;
    height: auto;
    margin: 150px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.doctor-add {
    width:800px;
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
}

.dr-heading {
    /* border-bottom: 4px solid ; */
}

.doctor-add-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.fields {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
}

.fields label {
    display: block;
}

.fields div {
    width: 100%;
}

.fields input {
    width: 100%;
    height: 40px;
    font-size: 18px;
    padding-left:10px;
}

.btns{
    text-align: center;
}

.btn {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    background-color:rgb(236, 64, 122) ;
    color: #fff;
    border:none;
    outline: none;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.btn:hover {
    transform: scale(0.95);
}

.fields {
    display: grid;
    grid-template-columns: repeat(2 , 1fr);
    width: 100%;
    margin-top: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Componants/adddoctorscomponant/adddoctor.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,uCAAuC;AAC3C;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,eAAe;IACf,mCAAmC;IACnC,WAAW;IACX,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sCAAsC;IACtC,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".add-doctor-section {\n    width: 100vw;\n    height: auto;\n    margin: 150px auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.doctor-add {\n    width:800px;\n    padding: 20px;\n    box-shadow: 0 5px 10px rgba(0,0,0,0.15);\n}\n\n.dr-heading {\n    /* border-bottom: 4px solid ; */\n}\n\n.doctor-add-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    gap: 10px;\n    margin-top: 10px;\n}\n\n.fields {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    gap: 15px;\n}\n\n.fields label {\n    display: block;\n}\n\n.fields div {\n    width: 100%;\n}\n\n.fields input {\n    width: 100%;\n    height: 40px;\n    font-size: 18px;\n    padding-left:10px;\n}\n\n.btns{\n    text-align: center;\n}\n\n.btn {\n    width: 100%;\n    padding: 10px;\n    font-size: 18px;\n    background-color:rgb(236, 64, 122) ;\n    color: #fff;\n    border:none;\n    outline: none;\n    border-radius: 10px;\n    transition: all 0.3s ease;\n}\n\n.btn:hover {\n    transform: scale(0.95);\n}\n\n.fields {\n    display: grid;\n    grid-template-columns: repeat(2 , 1fr);\n    width: 100%;\n    margin-top: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
