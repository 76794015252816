// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Appointment-main {
    width: 100%;
    height: 600px;
    position: relative;
}

.appointment-img-content {
    position: absolute;
    top: 20%;
    left: 11%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.ac-first {
    font-family: 'xr5_Raleway Bold';
    font-weight: bold;
    font-style: normal;
    font-size: 32px;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #C53A63;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;
}


.appointment-form {
    width: 100%;
    text-align: center;
    height: auto;
}

.appointment-content {
    font-family: 'xr5_Raleway Bold';
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #C53A63;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;
}

.appointment-details {
    margin:30px 50px ;
    width: 100%;
}

.appointment-details-content {
    width: 50%;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/Componants/AppointmentComponant/appointment.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,+BAA+B;IAC/B,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,cAAc;IACd,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;AACvB;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,+BAA+B;IAC/B,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,cAAc;IACd,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,YAAY;AAChB","sourcesContent":[".Appointment-main {\n    width: 100%;\n    height: 600px;\n    position: relative;\n}\n\n.appointment-img-content {\n    position: absolute;\n    top: 20%;\n    left: 11%;\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n.ac-first {\n    font-family: 'xr5_Raleway Bold';\n    font-weight: bold;\n    font-style: normal;\n    font-size: 32px;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #C53A63;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n}\n\n\n.appointment-form {\n    width: 100%;\n    text-align: center;\n    height: auto;\n}\n\n.appointment-content {\n    font-family: 'xr5_Raleway Bold';\n    font-weight: bold;\n    font-style: normal;\n    font-size: 24px;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #C53A63;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n}\n\n.appointment-details {\n    margin:30px 50px ;\n    width: 100%;\n}\n\n.appointment-details-content {\n    width: 50%;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
