// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clinical-supplies-main {
    width: 100%;
    height: 780px;
}

.clinical-supplies-main-content {
    position: relative;
    top: -75%;
    left: 10%;
    width: 600px;   
}

.clinical-products-filter {
    margin: 50px;
    background-color: #E3E2DF;
    height: 30px;
    padding: 40px;
    width: 80%;
    margin:50px auto    ;
}


.products {
    margin:30px auto;
    width: 80%;
    display: grid;
    grid-template-columns: repeat(3 , 1fr);
    gap: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Componants/ClinicalSuppliesComponant/ClinicalSupplies.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,UAAU;IACV,oBAAoB;AACxB;;;AAGA;IACI,gBAAgB;IAChB,UAAU;IACV,aAAa;IACb,sCAAsC;IACtC,SAAS;AACb","sourcesContent":[".clinical-supplies-main {\n    width: 100%;\n    height: 780px;\n}\n\n.clinical-supplies-main-content {\n    position: relative;\n    top: -75%;\n    left: 10%;\n    width: 600px;   \n}\n\n.clinical-products-filter {\n    margin: 50px;\n    background-color: #E3E2DF;\n    height: 30px;\n    padding: 40px;\n    width: 80%;\n    margin:50px auto    ;\n}\n\n\n.products {\n    margin:30px auto;\n    width: 80%;\n    display: grid;\n    grid-template-columns: repeat(3 , 1fr);\n    gap: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
