// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.labtest-1-main {
    width: 100%;
    height: 700px;
    position: relative;
}

.labtest-1-content {
    position: absolute;
    top: 92%;
    left: 10%;
}`, "",{"version":3,"sources":["webpack://./src/Componants/labtest1/labtest1.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb","sourcesContent":[".labtest-1-main {\n    width: 100%;\n    height: 700px;\n    position: relative;\n}\n\n.labtest-1-content {\n    position: absolute;\n    top: 92%;\n    left: 10%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
