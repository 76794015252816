// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
    width: 100%;  
}

.carousel-img-container {
    width: 100%;
    height: 70%;
}

.slick-prev {
    position: relative;
    left: 15px !important; 
    z-index: 999;
}

.slick-next {
    position: relative;
    right: 15px !important; 
    z-index: 999;
}

.slick-next,.slick-prev {
    background-color: transparent !important;
}

.slick-prev:before, .slick-next:before {
    font-size: 30px !important;
    color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/Componants/Carouselcomponant/carousel.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,0BAA0B;IAC1B,uBAAuB;AAC3B","sourcesContent":[".slider-container {\n    width: 100%;  \n}\n\n.carousel-img-container {\n    width: 100%;\n    height: 70%;\n}\n\n.slick-prev {\n    position: relative;\n    left: 15px !important; \n    z-index: 999;\n}\n\n.slick-next {\n    position: relative;\n    right: 15px !important; \n    z-index: 999;\n}\n\n.slick-next,.slick-prev {\n    background-color: transparent !important;\n}\n\n.slick-prev:before, .slick-next:before {\n    font-size: 30px !important;\n    color: black !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
