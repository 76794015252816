// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home1 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;   
}

.home1_right {
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Componants/home1componant/home1.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".home1 {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;   \n}\n\n.home1_right {\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
