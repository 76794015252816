// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Show-all-page2-main {
    width: 85%;
    margin: auto;
    display: flex;
    height: auto;
    gap: 100px;
}


.Show-all-page2-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px ;
}

.get-appointment-show-all-page2-content-div {
    width: 100%;
    height: 180px;
    margin: 10px 0;
    border: 3px solid black; 
    border-left: none;
    border-right: none;
    padding-top: 15px;
}


.show-content-Name {
    font-family: 'xr10_Open Sans';
    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #333333;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;
}

.show-all-btn {
    margin: 25px;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Componants/MadicalPackages/show-all-page2.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,UAAU;AACd;;;AAGA;IACI,aAAa;IACb,qCAAqC;IACrC,UAAU;AACd;;AAEA;IACI,WAAW;IACX,aAAa;IACb,cAAc;IACd,uBAAuB;IACvB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;AACrB;;;AAGA;IACI,6BAA6B;IAC7B,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,cAAc;IACd,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".Show-all-page2-main {\n    width: 85%;\n    margin: auto;\n    display: flex;\n    height: auto;\n    gap: 100px;\n}\n\n\n.Show-all-page2-content {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 40px ;\n}\n\n.get-appointment-show-all-page2-content-div {\n    width: 100%;\n    height: 180px;\n    margin: 10px 0;\n    border: 3px solid black; \n    border-left: none;\n    border-right: none;\n    padding-top: 15px;\n}\n\n\n.show-content-Name {\n    font-family: 'xr10_Open Sans';\n    font-weight: bold;\n    font-style: normal;\n    font-size: 20px;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #333333;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n}\n\n.show-all-btn {\n    margin: 25px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
