// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OurDoctor-container {
  width: 75%;
  height: 800px;
  margin:50px auto;
}

.our-doctor-heading {
  display: flex ;
  justify-content: space-between;
  margin: 0px 0px 20px 25px;
}

.our-doctor-filtersection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 35px;
}


.get-appointment-show-doctor-content {
  margin: 15px 0;
}


.getA-doctor-details {
  font-family: 'Raleway';
    font-weight: normal;
    font-style: normal;
    font-size: 20.33px;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #333333;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;
    line-height: 35px;
}

.getA-drName {
  font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/Componants/OurDoctorComponant/OurDoctor.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,8BAA8B;EAC9B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,SAAS;AACX;;;AAGA;EACE,cAAc;AAChB;;;AAGA;EACE,sBAAsB;IACpB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,cAAc;IACd,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".OurDoctor-container {\n  width: 75%;\n  height: 800px;\n  margin:50px auto;\n}\n\n.our-doctor-heading {\n  display: flex ;\n  justify-content: space-between;\n  margin: 0px 0px 20px 25px;\n}\n\n.our-doctor-filtersection {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 35px;\n}\n\n\n.get-appointment-show-doctor-content {\n  margin: 15px 0;\n}\n\n\n.getA-doctor-details {\n  font-family: 'Raleway';\n    font-weight: normal;\n    font-style: normal;\n    font-size: 20.33px;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #333333;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n    line-height: 35px;\n}\n\n.getA-drName {\n  font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
