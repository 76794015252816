// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manage-users-main {
    width:80%;
    margin:100px auto;
}

table, th, td {
    border: 1px solid black;
    gap: 15px;

}

th, td {
  padding: 10px;
  }`, "",{"version":3,"sources":["webpack://./src/Componants/ShowBookingComponant/showbooking.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;IACvB,SAAS;;AAEb;;AAEA;EACE,aAAa;EACb","sourcesContent":[".manage-users-main {\n    width:80%;\n    margin:100px auto;\n}\n\ntable, th, td {\n    border: 1px solid black;\n    gap: 15px;\n\n}\n\nth, td {\n  padding: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
