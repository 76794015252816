// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    padding:0 10%;
    background-color:  #F6F5F3;

}`, "",{"version":3,"sources":["webpack://./src/Componants/HomeComponant/home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0BAA0B;;AAE9B","sourcesContent":[".home {\n    padding:0 10%;\n    background-color:  #F6F5F3;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
