
export  const _userapiurl = "https://api.docnep.com/user/";

export const _adddoctorapiurl = "https://api.docnep.com/adddoctor/";

export const _adddiseaseapiurl = "https://api.docnep.com/adddisease/";

export const _addtestapiurl = "https://api.docnep.com/addtests/";

export const _addpackageapiurl = "https://api.docnep.com/addpackage/";

export const _addclinicalsuppliesapiurl = "https://api.docnep.com/addclinicalsupplies/";

export const _addcartapiurl = "https://api.docnep.com/addcart/";

export const _addorderapiurl = "https://api.docnep.com/addorders/";

// export  const _userapiurl = "http://147.79.66.193:3001/user/";

// export const _adddoctorapiurl = "http://147.79.66.193:3001/adddoctor/";

// export const _adddiseaseapiurl = "http://147.79.66.193:3001/adddisease/";

// export const _addtestapiurl = "http://147.79.66.193:3001/addtests/";

// export const _addpackageapiurl = "http://147.79.66.193:3001/addpackage/";

// export const _addclinicalsuppliesapiurl = "http://147.79.66.193:3001/addclinicalsupplies/";

// export const _addcartapiurl = "http://147.79.66.193:3001/addcart/";

// export const _addorderapiurl = "http://147.79.66.193:3001/addorders/";

// export  const _userapiurl = "http://localhost:3001/user/";

// export const _adddoctorapiurl = "http://localhost:3001/adddoctor/";

// export const _adddiseaseapiurl = "http://localhost:3001/adddisease/";

// export const _addtestapiurl = "http://localhost:3001/addtests/";

// export const _addpackageapiurl = "http://localhost:3001/addpackage/";

// export const _addclinicalsuppliesapiurl = "http://localhost:3001/addclinicalsupplies/";

// export const _addcartapiurl = "http://localhost:3001/addcart/";

// export const _addorderapiurl = "http://localhost:3001/addorders/";



