// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.madicine-uses-main {
    padding-top: 150px;
    width:85%;
    margin: auto;
}

.madicine-uses-main-home {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px;
}

.main-home-heading {
    font-family: Arial;
    font-weight: bold;
    font-style: normal;
    font-size: 42.44px;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #333333;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;

}

.main-home-content {
    margin-top: 20px;
    font-family: 'Raleway';
    font-weight: normal;
    font-style: normal;
    font-size: 20.22px;
    text-decoration: none;
    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;
    text-transform: none;
    color: #333333;
    background-color: Transparent;
    -webkit-text-stroke: 0px black;
    letter-spacing: 0em;
    line-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Componants/MadicineUsesComponant/Madicineuses.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,cAAc;IACd,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;;AAEvB;;AAEA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,mGAAmG;IACnG,oBAAoB;IACpB,cAAc;IACd,6BAA6B;IAC7B,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".madicine-uses-main {\n    padding-top: 150px;\n    width:85%;\n    margin: auto;\n}\n\n.madicine-uses-main-home {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 80px;\n}\n\n.main-home-heading {\n    font-family: Arial;\n    font-weight: bold;\n    font-style: normal;\n    font-size: 42.44px;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #333333;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n\n}\n\n.main-home-content {\n    margin-top: 20px;\n    font-family: 'Raleway';\n    font-weight: normal;\n    font-style: normal;\n    font-size: 20.22px;\n    text-decoration: none;\n    font-feature-settings: 'kern', 'liga' 0, 'clig' 0, 'calt' 0, 'titl' 0, 'salt' 0, 'swsh' 0, 'cswh' 0;\n    text-transform: none;\n    color: #333333;\n    background-color: Transparent;\n    -webkit-text-stroke: 0px black;\n    letter-spacing: 0em;\n    line-height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
