// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height:80px;
    background-color:rgb(227, 226, 223) ;
    padding:0 5%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 888888;
}

.header_nav {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header_links {
    color: rgb(236, 64, 122);
    font-family: Roboto ,  sans-serif;
    padding: 10px 20px;
    border-radius: 25px;
    transition: all ease-in-out 0.6s;
}

.header_links:hover, .active, .header_links:focus {
    background-color:rgb(236, 64, 122) ;
    color: #fff;
}



.dropdown {
    position: relative;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    display: none ;
    background-color:rgb(227, 226, 223) ;
    min-width: 180px;
    height: auto;
    left: -10%;
}

.dropdown-item {
    display: block;
    padding: 10px 25px;  
    color: rgb(236, 64, 122);     
}

.dropdown:hover  .dropdown-content {
    display: block;
}

.header_logo {
    margin-top: 20px;
    margin-left: 20px;
}

`, "",{"version":3,"sources":["webpack://./src/Componants/Headercomponant/header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,oCAAoC;IACpC,YAAY;IACZ,eAAe;IACf,MAAM;IACN,OAAO;IACP,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,wBAAwB;IACxB,iCAAiC;IACjC,kBAAkB;IAClB,mBAAmB;IACnB,gCAAgC;AACpC;;AAEA;IACI,mCAAmC;IACnC,WAAW;AACf;;;;AAIA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,cAAc;IACd,oCAAoC;IACpC,gBAAgB;IAChB,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".header {\n    width: 100vw;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    height:80px;\n    background-color:rgb(227, 226, 223) ;\n    padding:0 5%;\n    position: fixed;\n    top: 0;\n    left: 0;\n    z-index: 888888;\n}\n\n.header_nav {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n}\n\n.header_links {\n    color: rgb(236, 64, 122);\n    font-family: Roboto ,  sans-serif;\n    padding: 10px 20px;\n    border-radius: 25px;\n    transition: all ease-in-out 0.6s;\n}\n\n.header_links:hover, .active, .header_links:focus {\n    background-color:rgb(236, 64, 122) ;\n    color: #fff;\n}\n\n\n\n.dropdown {\n    position: relative;\n}\n\n.dropdown-content {\n    position: absolute;\n    top: 100%;\n    display: none ;\n    background-color:rgb(227, 226, 223) ;\n    min-width: 180px;\n    height: auto;\n    left: -10%;\n}\n\n.dropdown-item {\n    display: block;\n    padding: 10px 25px;  \n    color: rgb(236, 64, 122);     \n}\n\n.dropdown:hover  .dropdown-content {\n    display: block;\n}\n\n.header_logo {\n    margin-top: 20px;\n    margin-left: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
