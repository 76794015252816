import React from "react";
import './copywrite.css';

const Copywrite = () => {
  return (
    <>
      <div className="copywrite">© Copyright 2024 | Made By Mohit</div>
    </>
  );
};

export default Copywrite;
